var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-asset" },
    [
      _c("div", { staticClass: "audio-asset__equalizer" }),
      _vm.controls
        ? _c(
            "div",
            { staticClass: "audio-asset__player" },
            [
              _c("SyncedMedia", {
                ref: "element",
                attrs: {
                  src: _vm.src,
                  sync: _vm.isHostLike,
                  autoplay: false,
                  volume: _vm.volume,
                },
                model: {
                  value: _vm.state,
                  callback: function ($$v) {
                    _vm.state = $$v
                  },
                  expression: "state",
                },
              }),
              _c(
                "div",
                {
                  staticClass: "audio-asset__play-pause-btn",
                  on: { click: _vm.toggle },
                },
                [
                  _vm.paused
                    ? _c("i", { staticClass: "fas fa-play" })
                    : _c("i", { staticClass: "fas fa-pause" }),
                ]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.progress,
                    expression: "progress",
                  },
                ],
                attrs: { type: "range", min: "0", max: "100", step: "1" },
                domProps: { value: _vm.progress },
                on: {
                  __r: function ($event) {
                    _vm.progress = $event.target.value
                  },
                },
              }),
            ],
            1
          )
        : _c("SyncedMedia", {
            attrs: {
              src: _vm.src,
              sync: _vm.isHostLike,
              autoplay: false,
              volume: _vm.volume,
            },
            model: {
              value: _vm.state,
              callback: function ($$v) {
                _vm.state = $$v
              },
              expression: "state",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }