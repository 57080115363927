<template>
  <div class="audio-asset">
    <div class="audio-asset__equalizer" />
    <div class="audio-asset__player" v-if="controls">
      <SyncedMedia
        ref="element"
        v-model="state"
        :src="src"
        :sync="isHostLike"
        :autoplay="false"
        :volume="volume"
      />
      <div class="audio-asset__play-pause-btn" @click="toggle">
        <i class="fas fa-play" v-if="paused" />
        <i class="fas fa-pause" v-else />
      </div>
      <input type="range" min="0" max="100" v-model="progress" step="1" />
    </div>
    <SyncedMedia
      v-else
      v-model="state"
      :src="src"
      :sync="isHostLike"
      :autoplay="false"
      :volume="volume"
    />
  </div>
</template>

<script>
import SyncedMedia from "@/modules/smart-media/components/SyncedMedia"
import MediaAsset from "@/components/Assets/MediaAsset"
import { Media } from "@/components/GroupTeams/Common/SmartMedia.vue"

export default MediaAsset.extend({
  name: "AudioAsset",
  components: {
    SyncedMedia
  },
  props: {
    audio: {
      type: [String, Object],
      required: true
    },
    interrupt: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      paused: false,
      value: 0,
      req: null
    }
  },
  beforeDestroy() {
    cancelAnimationFrame(this.req)
  },
  methods: {
    toggle() {
      const el = this.$refs.element?.$el
      if (this.paused) return el?.play()
      return el?.pause()
    }
  },
  mounted() {
    const loop = () => {
      this.req = requestAnimationFrame(() => {
        const el = this.$refs.element?.$el
        if (el == null) return
        this.value = Math.round((el?.currentTime / el?.duration) * 100)
        this.paused = el?.paused
        loop()
      })
    }
    loop()
  },
  computed: {
    progress: {
      get() {
        return this.value
      },
      set(value) {
        const el = this.$refs.element?.$el
        if (el == null) return
        const currentTime =
          Math.round(el?.duration * (value / 100) * 1000) / 1000
        el.currentTime = currentTime
      }
    },
    src() {
      if (typeof this.audio === "string") return this.audio
      return this.audio?.source
    },
    volume() {
      const n = parseInt(this.audio?.volume)
      if (isNaN(n)) return 1
      return Math.round((n / 100) * 100) / 100
    }
  },
  created() {
    if (!this.interrupt) return
    Media.add(this._uid)
  },
  beforeDestroy() {
    if (!this.interrupt) return
    Media.rm(this._uid)
  }
})
</script>

<style lang="scss">
.audio-asset {
  $block: &;

  display: flex;
  padding: 10px;
  flex-direction: column;

  &__play-pause-btn {
    width: 24px;
    height: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    & > * {
      color: #fff;
    }
  }

  &__player {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    input[type="range"] {
      flex: 1;
      height: 8px;
      -webkit-appearance: none;
      background: #d3d3d3;
      outline: none;
      opacity: 0.6;
      border-radius: 5px;
      &:hover {
        opacity: 0.8;
      }
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        margin-top: -5px;
        margin-bottom: -5px;
        border-radius: 50%;
        background: $primary_accent_color;
        cursor: pointer;
      }
    }
  }

  &__equalizer {
    display: flex;
    flex: 1 auto;
    background-image: url("../../assets/equalizer-new.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 10px;
  }
}
</style>
