// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/equalizer-new.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* These colors are supposed to by dynamyc during the runtime */\n/* Please, don't place static colors here, if they are not supposed to be changed in runtime */\n/** Please, use this mixin as the SSOT **/\n/** $theme: \"light\" | \"dark\" **/\n.rtb-border-radius {\n  border-radius: 8px;\n}\n.rtb-border {\n  border: solid 0px var(--primary-accent-color);\n}\n.rtb-box-shadow {\n  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);\n}\n.audio-asset {\n  display: flex;\n  padding: 10px;\n  flex-direction: column;\n}\n.audio-asset__play-pause-btn {\n  width: 24px;\n  height: 30px;\n  display: flex;\n  justify-content: start;\n  align-items: center;\n  cursor: pointer;\n  z-index: 1;\n}\n.audio-asset__play-pause-btn > * {\n  color: #fff;\n}\n.audio-asset__player {\n  display: flex;\n  justify-content: start;\n  align-items: center;\n  margin-left: 20px;\n  margin-right: 20px;\n}\n.audio-asset__player input[type=range] {\n  flex: 1;\n  height: 8px;\n  -webkit-appearance: none;\n  background: #d3d3d3;\n  outline: none;\n  opacity: 0.6;\n  border-radius: 5px;\n}\n.audio-asset__player input[type=range]:hover {\n  opacity: 0.8;\n}\n.audio-asset__player input[type=range]::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  appearance: none;\n  width: 20px;\n  height: 20px;\n  margin-top: -5px;\n  margin-bottom: -5px;\n  border-radius: 50%;\n  background: var(--primary-accent-color);\n  cursor: pointer;\n}\n.audio-asset__equalizer {\n  display: flex;\n  flex: 1 auto;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center center;\n  margin-bottom: 10px;\n}", ""]);
// Exports
module.exports = exports;
